<template>
    <div class="uk-width-1-1">
        <!-- Header -->
        <div v-if="$can('READ', 'worker-payslip') || $can('EDIT', 'worker-payslip')" class="uk-flex uk-flex-middle uk-flex-between gap-10px">
            <div v-if="$can('READ', 'worker-payslip')" class="uk-width-1-1">
                <p class="uk-text-left uk-text-bold">Payslip Details</p>
            </div>
            <div v-if="$can('READ', 'worker-payslip') || $can('EDIT', 'worker-payslip')" class="uk-width-1-1">
                <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                    <div v-if="$can('READ', 'worker-payslip')" class="uk-width-1-2">
                        <button
                            v-if="downloadPayslipBatchTemplateLoading"
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded uk-width-expand"
                            disabled
                        >
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <button
                            v-else
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded uk-width-expand uk-text-center uk-text-break uk-position-relative myrobin-border-blue myrobin-color-blue"
                            @click="downloadPayslipBatchTemplate"
                        >
                            <img
                                class="uk-position-center-left uk-margin-small-left"
                                :src="`${images}/download-outline-blue.svg`"
                                alt="Upload icon"
                            >
                            <p class="uk-margin-remove">Download</p>
                        </button>
                    </div>
                    <div v-if="$can('EDIT', 'worker-payslip') && isEmptyField" class="uk-width-1-2">
                        <button
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded uk-width-expand uk-text-center uk-text-break uk-position-relative myrobin-border-green myrobin-color-green"
                            @click="copyFieldTemplate"
                        >   
                            <img
                                class="uk-position-center-left uk-margin-small-left"
                                :src="`${images}/copy-select-green.svg`"
                                alt="Upload icon"
                            >
                            <p class="uk-margin-remove">Copy Template Field</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End header -->

        <!-- Field category list -->
        <div v-if="$can('READ', 'worker-payslip')" class="uk-margin-top uk-width-1-1">
            <div class="uk-flex uk-flex-column uk-flex-left uk-flex-top gap-45px">
                <FieldCategoryCardItem
                    v-for="(category, index) in categoryList"
                    :key="index"
                    :table-loading="tableLoading"
                    :category-label="category && category.categoryLabel"
                    :category-value="category && category.categoryValue"
                    :field-list="category && category.fieldList"
                    :highlight-field="category && category.highlightField"
                    @set-as-highlight="setAsHighlight"
                    @edit-field="editField"
                    @add-field="addField"
                    @delete-field="deleteField"
                    @remove-field-as-highlight="removeFieldAsHighlight"
                />
            </div>
        </div>
        <!-- End field category list -->

        <!-- Handler when user can't access -->
        <div v-else>
            <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized for read data this page</p>
                    </span>
                </div>
            </div>
        </div>
        <!-- End handler when user can't access -->
    </div>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant";

export default {
    name: 'PayslipTemplate',
    components: {
        FieldCategoryCardItem: () => import('./FieldCategoryCardItem')
    },
    props: {
        tableLoading: {
            type: Boolean,
            required: true,
            default: false
        },
        downloadPayslipBatchTemplateLoading: {
            type: Boolean,
            required: true,
            default: false
        },
        isEmptyField: {
            type: Boolean,
            required: true,
            default: false
        },
        categoryList: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
        };
    },
    methods: {
        setAsHighlight({ action, typeLabel, typeValue, fieldName }) {
            this.$emit('set-as-highlight', { action, typeLabel, typeValue, fieldName });
        },
        editField({ action, typeLabel, typeValue, fieldName }) {
            this.$emit('edit-field', { action, typeLabel, typeValue, fieldName });
        },
        addField({ action, typeLabel, typeValue, fieldName }) {
            this.$emit('add-field', { action, typeLabel, typeValue, fieldName });
        },
        deleteField({ action, typeLabel, typeValue, fieldName }) {
            this.$emit('delete-field', { action, typeLabel, typeValue, fieldName });
        },
        downloadPayslipBatchTemplate() {
            this.$emit('download-payslip-batch-template');
        },
        copyFieldTemplate() {
            this.$emit('copy-field-template');
        },
        removeFieldAsHighlight({ action, typeLabel, typeValue, fieldName }) {
            this.$emit('remove-field-as-highlight', { action, typeLabel, typeValue, fieldName });
        } 
    }
};
</script>

<style scoped>
.myrobin-border-blue {
    border: 1px solid #0275D8;
}
.myrobin-border-green {
    border: 1px solid #0ABAB5;
}
.myrobin-color-green {
    color: #0ABAB5;
}
.myrobin-border-none {
    border: none;
}

.gap-10px {
    gap: 10px;
}
.gap-45px {
    gap: 45px;
}
</style>
